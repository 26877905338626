/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  //{ loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61947f7302848e0ea77e8915").then(body => body.text()), isAsync: false },
  //{ loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class ProjectsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ProjectsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ProjectsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '622b0695493fdc41e9d86d72'
    htmlEl.dataset['wfSite'] = '61947f7302848e0ea77e8915'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = ProjectsView.Controller !== ProjectsView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/robinland.webflow.css);

          ::-webkit-scrollbar{ width:0px; height:0px; }
        ` }} />
        <span className="af-view">
          <div className="af-class-projects-page">
            <div data-animation="default" className="af-class-fullscreen-nav w-nav" data-easing2="ease-out" data-easing="ease-in" data-collapse="all" role="banner" data-no-scroll={1} data-duration={0} id="Top-Of-Page" data-doc-height={1}>
              <nav id="Navigation" data-w-id="aba33bfd-b834-53e1-af49-09db9b937330" className="af-class-nav-items">
                <a href="index.html" className="af-class-link-block-56 w-inline-block"><img src="images/Logo.png" width={200} alt className="af-class-robinland-icon" /></a>
                <div data-w-id="aba33bfd-b834-53e1-af49-09db9b937334" className="af-class-hamburger-menu-2 w-nav-button">
                  <div className="af-class-menu">
                    <div className="af-class-line" />
                    <div className="af-class-line-2" />
                  </div>
                </div>
                <div className="af-class-buttons-container">
                  <a href="projects.html" aria-current="page" className="af-class-button-projects w-inline-block w--current">
                    <div>View Projects</div>
                  </a>
                  <a href="/#claim-early-access" className="af-class-button-claim-early-access w-inline-block">
                    <div className="af-class-text-block-65">Claim Early Access</div>
                  </a>
                </div>
                <nav role="navigation" className="af-class-drop-down-menu-2 w-nav-menu">
                  <div className="af-class-columns-23 w-row">
                    <div className="w-col w-col-6 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">DISCOVER</div>
                      <a href="get-involved.html" className="af-class-involve-link w-inline-block">
                        <div className="af-class-text-block-43">Involve.</div>
                      </a>
                      <a href="projects.html" aria-current="page" className="af-class-projects-link w-inline-block w--current">
                        <div className="af-class-text-block-43">Projects.</div>
                      </a>
                    </div>
                    <div className="af-class-column-20 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">CONTACT</div>
                      <a href="mailto:team@robinland.io?subject=Reaching%20out" className="af-class-link-block-29 w-inline-block">
                        <div className="af-class-text-block-41">team@robinland.io</div>
                      </a>
                    </div>
                    <div className="af-class-column-19 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">SOCIALS</div>
                      <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-link-block-26 w-inline-block">
                        <div className="af-class-text-block-40">LinkedIn</div>
                      </a>
                      <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Twitter</div>
                      </a>
                      <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Medium</div>
                      </a>
                    </div>
                  </div>
                </nav>
              </nav>
            </div>
            <section id="main" className="af-class-project-summaries w-row">
              <div className="af-class-column-30 w-col w-col-6 w-col-stack">
                <h1 className="af-class-projects-title">A Growing Portfolio</h1>
                <h4 className="af-class-projects-subtitle">At Robinland, we are working on some exciting new projects that benefits both parties: for real estate developers, this is a much faster, cheaper, and hassle-free way of accessing liquidity; for DeFi-lenders, this is a scalable technology allowing them to include high-quality interest-bearing stable real world asset into their collateral pool, enhancing the stability and credibility of their lending facility.</h4>
              </div>
              <div className="af-class-column-6-copy w-col w-col-6 w-col-stack">
                <div data-w-id="1c3a6ccc-e5e9-fc76-c6d5-adc530f2087f" style={{opacity: 0}} className="af-class-blender-embed w-embed w-iframe">
                  <div className="af-class-sketchfab-embed-wrapper">
                    <iframe title="Projects" width="500vw" height="600vh" autospin={1} frameBorder={0} ui_stop={0} allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/cf4c279a24dc4371a82dfcbbe4122d9d/embed?autostart=1&ui_theme=dark">
                    </iframe>
                  </div>
                </div>
              </div>
            </section>
            <section id="one" className="af-class-project-container w-row">
              <div className="af-class-left-side-projects w-col w-col-4">
                <div className="af-class-div-block-18">
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7ac" style={{opacity: 0}} className="af-class-heading-project-copy">Project One.</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7ae" style={{opacity: 0}} className="af-class-projects-subheading-white">Property Type</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7b0" style={{opacity: 0}} className="af-class-projects-description-white">Mixed Use (Commercial + Residential)</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7b2" style={{opacity: 0}} className="af-class-projects-subheading-white">Investment Type</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7b4" style={{opacity: 0}} className="af-class-projects-description-white">Equity Pledge Loan</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7b6" style={{opacity: 0}} className="af-class-projects-subheading-white">Estimated Return</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7b8" style={{opacity: 0}} className="af-class-projects-description-white">8.00% - 8.25%</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7ba" style={{opacity: 0}} className="af-class-projects-subheading-white">Investment Horizon</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7bc" style={{opacity: 0}} className="af-class-projects-description-white">3 — 6 Months</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7be" style={{opacity: 0}} className="af-class-projects-subheading-white">Offering Amount</div>
                  <div data-w-id="1892141c-2bf9-d285-ca70-f3619e76d7c0" style={{opacity: 0}} className="af-class-projects-description-white">$1,500,000</div>
                </div>
              </div>
              <div className="af-class-the-right-side w-col w-col-8"><img src="images/813_overview_01.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1919px) 298.109375px, 335.375px" srcSet="images/813_overview_01-p-500.png 500w, images/813_overview_01.png 553w" alt className="af-class-image-31" /><img src="images/Project-1.png" loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 87vw, (max-width: 991px) 57vw, (max-width: 1439px) 50vw, 800px" srcSet="images/Project-1-p-500.png 500w, images/Project-1.png 800w" alt className="af-class-metrics" /></div>
            </section>
            <section id="two" className="af-class-project-right-container w-row">
              <div className="af-class-left-side-copy w-col w-col-7"><img src="images/818_overview_01.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 350px, (max-width: 1919px) 450px, 549.984375px" srcSet="images/818_overview_01-p-500.png 500w, images/818_overview_01-p-800.png 800w, images/818_overview_01-p-1080.png 1080w, images/818_overview_01.png 1191w" alt className="af-class-image-31-copy" /><img src="images/Project-2.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 54vw, (max-width: 1439px) 43vw, (max-width: 1919px) 46vw, 800px" srcSet="images/Project-2-p-500.png 500w, images/Project-2.png 800w" alt className="af-class-metrics" /></div>
              <div className="af-class-the-right-side w-col w-col-5">
                <div className="af-class-div-block-18-copy-copy">
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467f1" style={{opacity: 0}} className="af-class-heading-project-copy-copy">Project Two.</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467f3" style={{opacity: 0}} className="af-class-right-side-heading">Property Type</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467f5" style={{opacity: 0}} className="af-class-right-side-description">Mixed Use (Commercial + Residential)</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467f7" style={{opacity: 0}} className="af-class-right-side-heading">Investment Type</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467f9" style={{opacity: 0}} className="af-class-right-side-description">Mezzanine Loan</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467fb" style={{opacity: 0}} className="af-class-right-side-heading">Estimated Return</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467fd" style={{opacity: 0}} className="af-class-right-side-description">8.00% - 8.25%</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c3467ff" style={{opacity: 0}} className="af-class-right-side-heading">Investment Horizon</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c346801" style={{opacity: 0}} className="af-class-right-side-description">12 — 24 Months</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c346803" style={{opacity: 0}} className="af-class-right-side-heading">Offering Amount</div>
                  <div data-w-id="461f2084-251a-7eb0-13b7-15446c346805" style={{opacity: 0}} className="af-class-right-side-description">$1,500,000</div>
                </div>
              </div>
            </section>
            <section id="Projects-Two" className="af-class-project-number-two-mobile w-row">
              <div className="af-class-left-side-projects w-col w-col-7">
                <div className="af-class-div-block-18-copy-copy">
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671332" style={{opacity: 0}} className="af-class-heading-project-copy-copy">Project Two.</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671334" style={{opacity: 0}} className="af-class-right-side-heading">Property Type</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671336" style={{opacity: 0}} className="af-class-right-side-description">Mixed Use (Commercial + Residential)</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671338" style={{opacity: 0}} className="af-class-right-side-heading">Investment Type</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b285967133a" style={{opacity: 0}} className="af-class-right-side-description">Mezzanine Loan</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b285967133c" style={{opacity: 0}} className="af-class-right-side-heading">Estimated Return</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b285967133e" style={{opacity: 0}} className="af-class-right-side-description">8.00% - 8.25%</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671340" style={{opacity: 0}} className="af-class-right-side-heading">Investment Horizon</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671342" style={{opacity: 0}} className="af-class-right-side-description">12 — 24 Months</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671344" style={{opacity: 0}} className="af-class-right-side-heading">Offering Amount</div>
                  <div data-w-id="2e609549-a00e-fb30-2e46-0b2859671346" style={{opacity: 0}} className="af-class-right-side-description">$1,500,000</div>
                </div>
              </div>
              <div className="af-class-the-right-side-copy w-col w-col-5"><img src="images/818_overview_01.png" loading="lazy" sizes="(max-width: 479px) 84vw, (max-width: 767px) 375px, 100vw" srcSet="images/818_overview_01-p-500.png 500w, images/818_overview_01-p-800.png 800w, images/818_overview_01-p-1080.png 1080w, images/818_overview_01.png 1191w" alt className="af-class-project-image-copy" /><img src="images/Project-2.png" loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 87vw, 100vw" srcSet="images/Project-2-p-500.png 500w, images/Project-2.png 800w" alt className="af-class-metrics" /></div>
            </section>
            <section id="three" className="af-class-project-container w-row">
              <div className="af-class-left-side-projects w-col w-col-4">
                <div className="af-class-div-block-18">
                  <div data-w-id="e7e2ca88-2544-f0f5-2add-686b21ac0ed5" style={{opacity: 0}} className="af-class-heading-project-copy">Project Three.</div>
                  <div data-w-id="1517ba3f-ea03-2df1-e458-f49fc664ee79" style={{opacity: 0}} className="af-class-projects-subheading-white">Property Type</div>
                  <div data-w-id="fa498929-0f77-3375-e19d-6285f104cfef" style={{opacity: 0}} className="af-class-projects-description-white">Mixed Use (Commercial + Residential)</div>
                  <div data-w-id="de0b6308-14c3-c96a-845b-26f71ae98137" style={{opacity: 0}} className="af-class-projects-subheading-white">Investment Type</div>
                  <div data-w-id="2509476d-32dc-19b1-d67f-8b1100727462" style={{opacity: 0}} className="af-class-projects-description-white">Mezzanine Loan</div>
                  <div data-w-id="d7410d69-fa73-69c9-14fa-2dda95379706" style={{opacity: 0}} className="af-class-projects-subheading-white">Estimated Return</div>
                  <div data-w-id="ee2c226c-e71f-c6be-cb23-cb791596d4ac" style={{opacity: 0}} className="af-class-projects-description-white">8.00% - 8.25%</div>
                  <div data-w-id="721d0b09-1b4a-c06f-feb5-2bbd5b75100c" style={{opacity: 0}} className="af-class-projects-subheading-white">Investment Horizon</div>
                  <div data-w-id="7cf09bf8-79e9-977f-621f-d4f35212b5f3" style={{opacity: 0}} className="af-class-projects-description-white">12 — 24 Months</div>
                  <div data-w-id="e3407472-766b-01da-72fa-4ec41d72721c" style={{opacity: 0}} className="af-class-projects-subheading-white">Offering Amount</div>
                  <div data-w-id="42bda57a-1df9-d32d-9f41-b643d4e23dfd" style={{opacity: 0}} className="af-class-projects-description-white">$2,500,000</div>
                </div>
              </div>
              <div className="af-class-the-right-side w-col w-col-8"><img src="images/819_overview_01.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1919px) 319.75px, 349.984375px" srcSet="images/819_overview_01-p-500.png 500w, images/819_overview_01-p-800.png 800w, images/819_overview_01-p-1080.png 1080w, images/819_overview_01.png 1295w" alt className="af-class-image-31" /><img src="images/Project-3.png" loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 87vw, (max-width: 991px) 57vw, (max-width: 1439px) 50vw, 800px" srcSet="images/Project-3-p-500.png 500w, images/Project-3.png 800w" alt className="af-class-metrics" /></div>
            </section>
            <div className="af-class-progress-container">
              <div className="af-class-progress-bar-grey-copy" />
            </div>
            <div className="af-class-progress-container-moving">
              <div data-w-id="19e33bbe-332e-2de1-183c-b18e919d2267" className="af-class-progress-bar" />
            </div>
            <div className="af-class-projects-footer">
              <div className="af-class-footer-items-container">
                <a href="index.html" className="af-class-robinland-link w-inline-block"><img src="images/Logo.png" loading="lazy" alt className="af-class-image-13" /></a>
                <div className="af-class-footer-item">
                  <a href="https://docs.robinland.io" target="_blank" className="af-class-black-link-1">Docs</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-black-link-2">LinkedIn</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-black-link-3">Twitter</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-black-link-4">Medium</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.blog.robinland.io/" target="_blank" className="af-class-black-link-5">Blog</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="mailto:team@robinland.io?subject=Reaching%20Out!" className="af-class-black-link-6">team@robinland.io</a>
                </div>
              </div>
              <div className="af-class-copyright-black">Copyright © Robinland Holdings LLC., 2022. All Rights Reserved.</div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ProjectsView

/* eslint-enable */