import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './scripts'
import './styles'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import IndexController from './controllers/IndexController';
import GetInvolvedController from './controllers/GetInvolvedController';
import ProjectsController from './controllers/ProjectsController';
import NotFoundController from './controllers/NotFoundController';
import UnauthorizedController from './controllers/UnauthorizedController';
import loadjs from 'loadjs';
import * as serviceWorker from './serviceWorker';
// to support Montserrat https://www.npmjs.com/package/@fontsource/montserrat
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

class App extends React.Component {
  // to make sure webflow function properly 
  // On Enter page animations: 
  // Unfortunately, “On Enter” page transitions don’t transfer over very easily to React applications from Webflow. It’s possible to get them to work on initial page load, but when leaving and returning to a page using react router, the animation may not retrigger.
  // https://colekillian.com/posts/converting-webflow-site-to-react-with-appfairy/
  componentDidMount() {
    loadjs(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61947f7302848e0ea77e8915",
      function () {
        loadjs("js/webflow.js");
      }
    );
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<IndexController/>}></Route>
          <Route exact path="/index.html" element={<Navigate to="/" replace/>}></Route>
          <Route exact path="/get-involved" element={<GetInvolvedController/>}></Route>
          <Route exact path="/get-involved.html" element={<Navigate to="/get-involved" replace/>}></Route>
          <Route exact path="/projects" element={<ProjectsController/>}></Route>
          <Route exact path="/projects.html" element={<Navigate to="/projects" replace/>}></Route>
          <Route exact path="/unauthorized" element={<UnauthorizedController/>}></Route>
          <Route exact path="/unauthorized.html" element={<Navigate to="/unauthorized" replace/>}></Route>
          <Route exact path="/not-found" element={<NotFoundController/>}></Route>
          <Route exact path="/not-found.html" element={<Navigate to="/not-found" replace/>}></Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

serviceWorker.unregister();