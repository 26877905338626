/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  //{ loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61947f7302848e0ea77e8915").then(body => body.text()), isAsync: false },
  //{ loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]
const emailRegisterUrl = process.env.REACT_APP_ROBINLAND_API_URL + "/api/email"

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '622b0695493fdc6e5ed86d68'
    htmlEl.dataset['wfSite'] = '61947f7302848e0ea77e8915'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/robinland.webflow.css);

          ::-webkit-scrollbar{ width:0px; height:0px; }
        ` }} />
        <span className="af-view">
          <div className="af-class-robinland-page">
            <div data-animation="default" className="af-class-fullscreen-nav w-nav" data-easing2="ease-out" data-easing="ease-in" data-collapse="all" role="banner" data-no-scroll={1} data-duration={0} id="Top-Of-Page" data-doc-height={1}>
              <nav id="Navigation" data-w-id="aba33bfd-b834-53e1-af49-09db9b937330" className="af-class-nav-items">
                <a href="index.html" aria-current="page" className="af-class-link-block-56 w-inline-block w--current"><img src="images/Logo.png" width={200} alt className="af-class-robinland-icon" /></a>
                <div data-w-id="aba33bfd-b834-53e1-af49-09db9b937334" className="af-class-hamburger-menu-2 w-nav-button">
                  <div className="af-class-menu">
                    <div className="af-class-line" />
                    <div className="af-class-line-2" />
                  </div>
                </div>
                <div className="af-class-buttons-container">
                  <a href="projects.html" className="af-class-button-projects w-inline-block">
                    <div>View Projects</div>
                  </a>
                  <a href="/#claim-early-access" className="af-class-button-claim-early-access w-inline-block">
                    <div className="af-class-text-block-65">Claim Early Access</div>
                  </a>
                </div>
                <nav role="navigation" className="af-class-drop-down-menu-2 w-nav-menu">
                  <div className="af-class-columns-23 w-row">
                    <div className="w-col w-col-6 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">DISCOVER</div>
                      <a href="get-involved.html" className="af-class-involve-link w-inline-block">
                        <div className="af-class-text-block-43">Involve.</div>
                      </a>
                      <a href="projects.html" className="af-class-projects-link w-inline-block">
                        <div className="af-class-text-block-43">Projects.</div>
                      </a>
                    </div>
                    <div className="af-class-column-20 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">CONTACT</div>
                      <a href="mailto:team@robinland.io?subject=Reaching%20out" className="af-class-link-block-29 w-inline-block">
                        <div className="af-class-text-block-41">team@robinland.io</div>
                      </a>
                    </div>
                    <div className="af-class-column-19 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">SOCIALS</div>
                      <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-link-block-26 w-inline-block">
                        <div className="af-class-text-block-40">LinkedIn</div>
                      </a>
                      <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Twitter</div>
                      </a>
                      <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Medium</div>
                      </a>
                    </div>
                  </div>
                </nav>
              </nav>
            </div>
            <section id="Top" className="af-class-landing-section af-class-wf-section">
              <div className="af-class-main-container w-row">
                <div className="af-class-main-left-side w-col w-col-6">
                  <h1 className="af-class-title">The Future of Real Estate</h1>
                  <h3 className="af-class-subtitle">Harnessing Power of Blockchain.</h3>
                  <h4 className="af-class-main-subtitle">Real estate investment, tokenized using blockchain.</h4>
                  <div data-w-id="8d90dbfe-5127-976b-9721-655175a17f36" style={{opacity: 0}} className="af-class-email-signup-section w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" action={emailRegisterUrl} method="post" className="af-class-email-signup-container"><input type="email" className="af-class-email-signup-box w-input" maxLength={256} name="Email" data-name="Email 2" placeholder="Email Address" id="Email" required /><input type="submit" value="Claim Early Access" data-wait="Please wait..." className="af-class-email-signup-submit-button w-button" /></form>
                    <div className="af-class-success-message-4 w-form-done" />
                    <div className="af-class-error-message-3 w-form-fail" />
                    <div data-w-id="e2de4b31-1b1a-1e44-5936-9e7bee4fd244" style={{opacity: 0}} className="af-class-get-involved-mini">
                      <h5 className="af-class-looking-to-partner">Looking to partner?</h5>
                      <h5 className="af-class-heading-6">Reach us</h5>
                      <a href="get-involved.html" className="af-class-link-block-57 w-inline-block">
                        <h5 className="af-class-heading-7">here</h5>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="af-class-main-right-side w-col w-col-6">
                  <div data-w-id="91e089f4-130a-147c-3abc-ca5ea6bed940" data-animation-type="lottie" data-src="documents/lf30_editor_xbjiqpzh.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="4.016666666666667" data-duration={0} className="af-class-right-lottie" />
                </div>
              </div>
            </section>
            <section id="mission" className="af-class-our-mission w-row">
              <div className="af-class-left-side-bigger w-col w-col-6">
                <h1 className="af-class-medium-title">Building a New Generation</h1>
                <h3 className="af-class-subtitle">Of Decentralized Financial Fluidity.</h3>
                <h4 className="af-class-description-beta">Robinland is a bridge between trad-fi and defi: we turn real estate assets into defi native collateral by tokenization, which allows Robinland to channel liquidity from defi lenders to developers.</h4>
              </div>
              <div className="af-class-right-side-picture w-col w-col-6">
                <div data-w-id="969083a0-c900-418d-9c73-693e4114e13a" data-animation-type="lottie" data-src="documents/lf30_editor_g9i4w8rw.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration={4} data-duration={0} className="af-class-right-lottie" />
              </div>
            </section>
            <section id="projects" className="af-class-our-projects w-row">
              <div className="af-class-left-side w-col w-col-6">
                <h1 className="af-class-medium-title">Our Flourishing Projects</h1>
                <h3 className="af-class-subtitle">That Make Investing Simple.</h3>
                <h4 className="af-class-description-beta">We here at Robinland have some exciting new projects in the mix! Below you can view our sample projects and get an idea of how we do business around here.</h4>
                <a href="projects.html" className="af-class-button-projects-copy w-inline-block">
                  <div className="af-class-text-block-35">View Projects</div>
                </a>
              </div>
              <div className="af-class-right-side-picture w-col w-col-6">
                <div data-w-id="2d61039e-bb4c-77d4-e540-d0eda98b9bc7" data-animation-type="lottie" data-src="documents/lf30_editor_5caz4g3s.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="9.8" data-duration={0} className="af-class-right-lottie" />
              </div>
            </section>
            <section id="for-retail-investor" className="af-class-retail w-row">
              <div className="af-class-left-side-normal w-col w-col-5">
                <h1 className="af-class-small-title">Effortless Real Estate Investment</h1>
                <h3 className="af-class-subtitle-grey">For Retail Investors.</h3>
                <h4 className="af-class-description-beta">We allow retail investors anywhere in the world to trade real estate like they trade stocks on Robinhood - small ticket size + large liquidity.</h4>
                <a href="/#claim-early-access" className="af-class-button-claim w-inline-block">
                  <div className="af-class-text-block-35">Claim Early Access</div>
                </a>
              </div>
              <div className="af-class-middle-normal w-col w-col-3">
                <div data-w-id="26c0e0b0-3577-ed44-20b1-e8d5c9fe2c94" data-animation-type="lottie" data-src="documents/lf30_editor_pi8hwnms.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="4.8" data-duration={0} className="af-class-middle-lottie" />
              </div>
              <div className="af-class-right-side-normal w-col w-col-4">
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Lower entry barrier</div>
                  <h4 className="af-class-description-beta">With tokenization, retail investors can now gain access to high-quality commercial real estate which are usually only accessible to large institutional investors.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Instant liquidity</div>
                  <h4 className="af-class-description-beta">With technical innovations in DeFi such as AMM (automated market making), investors can trade these tokens anytime even when there is no counter-party.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Passive income</div>
                  <h4 className="af-class-description-beta">Without having to actively manage a property, investors can gain interest or rental dividends deposited into their wallet in a hassle-free fashion.</h4>
                </div>
              </div>
            </section>
            <div className="af-class-timeline">
              <div className="af-class-div-block-51">
                <div className="af-class-text-block-66">Behind the Scene</div>
                <div className="af-class-div-block-50">
                  <div className="af-class-subdescription-bold">After You've Invested, </div>
                  <div className="af-class-subdescription">real estate developer regularly pays you the interests of your investment</div>
                </div>
              </div>
              <div className="af-class-timeline-section">
                <div className="af-class-timeline-container">
                  <div className="af-class-timeline-components">
                    <div className="af-class-timeline_progress">
                      <div className="af-class-timeline_progress-bar" />
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d65f" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d660-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">Real Estate Developer</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d663-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d665-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/6---Building.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Borrows from Robinland as a faster &amp;&nbsp;cheaper source of financing.</div>
                      </div>
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d66b" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d66c-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">Pays USD</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d66f-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-c13b5a26-4b93-bb63-7982-ce9844207c4f-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/8---Time-Money.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Monthly interests of the real estate debt.</div>
                      </div>
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d67e" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d67f-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">Robinland</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d682-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-_03fc9ff9-fdc5-9e5a-c257-47519df37a48-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/100.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Receives the paid interests and looks up the distributed ledger on the blockchain that safely stores the info on the Security Token owners in a decentralized fashion.</div>
                      </div>
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d698" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d699-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">Sends USD</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d69c-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-_4dabc077-7532-14e8-adf1-12ffa8a40128-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/22---Coin-Increase.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Monthly interests of the real estate debt, broken down into each Security Tokens owner's fair share.</div>
                      </div>
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d6b4" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d6b5-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">Security Token Owners</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d6b8-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-b2dfd562-d9bf-1478-dc98-24cd73b38498-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/20---Management-People.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Retail investors anywhere in the world that own the Security Tokens pegged to the real estate debt.</div>
                      </div>
                    </div>
                    <div data-w-id="2b385b11-bd56-0383-5865-c32cf4f5d6d1" className="af-class-timeline_item">
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d6d2-5ed86d68" className="af-class-timeline_left">
                        <div className="af-class-timeline_date-text">To Bank Account</div>
                      </div>
                      <div id="w-node-_2b385b11-bd56-0383-5865-c32cf4f5d6d5-5ed86d68" className="af-class-timeline_centre">
                        <div className="af-class-timeline_circle" />
                      </div>
                      <div id="w-node-fc6232c4-ac06-e066-0ae5-903001680c19-5ed86d68" className="af-class-timeline_right">
                        <div className="af-class-timeline_image-wrapper"><img src="images/25---Bag.png" loading="lazy" width={480} alt className="af-class-image-45" /></div>
                        <div className="af-class-description-copy">Investors can withdraw USD&nbsp;from their account balances on the Robinland platform to their own bank accounts.</div>
                      </div>
                    </div>
                    <div className="af-class-overlay-fade-bottom" />
                    <div className="af-class-overlay-fade-top" />
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-div-block-54">
              <a href="https://docs.robinland.io/" target="_blank" className="af-class-button-learn-more w-inline-block">
                <div className="af-class-text-block-35">Learn More</div>
              </a>
            </div>
            <section id="for-crypto-investor" className="af-class-crypto w-row">
              <div className="af-class-left-side-normal w-col w-col-5">
                <h1 className="af-class-small-title">Crypto Bond</h1>
                <h3 className="af-class-subtitle-grey">For DeFi Investors.</h3>
                <h4 className="af-class-description-beta">We offer a way to park cash for investors to gain yields higher than the risk-free rate currently in DeFi and offer volatility that is orthogonal to the rest of the crypto market.</h4>
                <a href="/#claim-early-access" className="af-class-button-claim w-inline-block">
                  <div className="af-class-text-block-35">Claim Early Access</div>
                </a>
              </div>
              <div className="af-class-extra-space w-col w-col-3">
                <div data-w-id="309b0503-6757-f671-4597-7c62f25aba95" data-animation-type="lottie" data-src="documents/61761-crypto-animation.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="14.848180910069386" data-duration={0} className="af-class-middle-lottie" />
              </div>
              <div className="af-class-right-side-normal w-col w-col-4">
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">High Yield</div>
                  <h4 className="af-class-description-beta">With our underlying real estate project generating steady rental/interest dividends, our tokens offer buyers yields higher than what is currently available in DeFi.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Orthogonal Volatility</div>
                  <h4 className="af-class-description-beta">While other lending protocols are based on crypto-native asset, we offer volatility based on real world asset, providing investors natural diversification.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Flexible Interest</div>
                  <h4 className="af-class-description-beta">The longer one locks their cash in our tokens, the higher the return one can expect, providing a complete menu of liquidity-return trade-off choices.</h4>
                </div>
              </div>
            </section>
            <section id="for-real-estate" className="af-class-for-real-estate w-row">
              <div className="af-class-left-side-normal w-col w-col-5">
                <h1 className="af-class-small-title">Embracing Innovation</h1>
                <h3 className="af-class-subtitle-grey">For Real Estate Developers.</h3>
                <h4 className="af-class-description-beta">We are a new breed of financing that dominates your existing options - banks, real estate PE, and crowdfunding platforms - both in terms of speed and cost.</h4>
                <a href="get-involved.html" className="af-class-button-involve w-inline-block">
                  <div className="af-class-text-block-35">Get Involved</div>
                </a>
              </div>
              <div className="af-class-middle-normal w-col w-col-3">
                <div data-w-id="64e255da-c2cb-2e23-dfaf-19228796a763" data-animation-type="lottie" data-src="documents/lf30_editor_i7cupdch.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration={2} data-duration={0} className="af-class-middle-lottie" />
              </div>
              <div className="af-class-right-side-normal w-col w-col-4">
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Lower Cost</div>
                  <h4 className="af-class-description-beta">Defi lenders provide much lower cost of financing compared to traditional sources like banks, real estate PE or crowdfunding platform.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Faster Turnaround</div>
                  <h4 className="af-class-description-beta">Thanks to the DAO (decentralized autonomous organization) system, approvals for credit are much faster than traditional sources of financing.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Stable Financing</div>
                  <h4 className="af-class-description-beta">Unlike bank financing that can be retracted for arbitrary reasons, the revolving credit lines from Defi lenders ensures stability of financing for borrowers.</h4>
                </div>
              </div>
            </section>
            <section id="for-defi-lenders" className="af-class-for-defi-lenders w-row">
              <div className="af-class-left-side-normal w-col w-col-5">
                <h1 className="af-class-small-title">Unlocking Opportunities</h1>
                <h3 className="af-class-subtitle-grey">For DeFi Lenders.</h3>
                <h4 className="af-class-description-beta">We allow DeFi Lenders to expand their RWA (real world asset) collateral pool without the hassle of dealing with real-world logistics and operations.</h4>
                <a href="get-involved.html" className="af-class-button-involve w-inline-block">
                  <div className="af-class-text-block-35">Get Involved</div>
                </a>
              </div>
              <div className="af-class-middle-normal w-col w-col-3">
                <div data-w-id="dd02500f-7b62-349b-4e35-ee7607efe20f" data-animation-type="lottie" data-src="documents/lf30_editor_m5yluol4.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="4.033333333333333" data-duration={0} className="af-class-middle-lottie" />
              </div>
              <div className="af-class-right-side-normal w-col w-col-4">
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">High Quality Collateral</div>
                  <h4 className="af-class-description-beta">Robinland integrates one of the highest quality collateral - real estate - into the DeFi lending ecosystem.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Hassle Free RWA</div>
                  <h4 className="af-class-description-beta">With Robinland turning RWA into crypto-native tokens, DeFi lender can enjoy the stability brought by real world asset without real world hassle.</h4>
                </div>
                <div className="af-class-bullet-one">
                  <div className="af-class-subheading">Risks, Managed</div>
                  <h4 className="af-class-description-beta">With our proprietary project evaluation system, Robinland serves as the risk controller and also buffers the DeFi lender from defaults of underlying asset.</h4>
                </div>
              </div>
            </section>
            <div id="claim-early-access" className="af-class-newsletter-container">
              <div data-w-id="5244914f-5f68-993c-9ff5-c4f557da826b" style={{opacity: 0}} className="af-class-block">
                <div className="af-class-text-block-60">Sign up today to join our marketplace.</div>
                <div className="af-class-bottom-cta">
                  <div className="af-class-bottom-signup-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" action={emailRegisterUrl} method="post" className="af-class-form"><input type="email" className="af-class-bottom-cta-box w-input" maxLength={256} name="Email" data-name="Email 2" placeholder="Your email address" id="Email" required /><input type="submit" value="Claim Early Access" data-wait="Please wait..." className="af-class-bottom-cta-button w-button" /></form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <div className="af-class-desktop-newsletter-container">
                    <div className="af-class-desktop-newsletter">
                      <div className="af-class-div-block-48"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                        <div className="af-class-text-block-61">Join Newsletter</div>
                      </div>
                      <div className="af-class-div-block-48"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                        <div className="af-class-text-block-61">Stay Up To Date</div>
                      </div>
                    </div>
                    <div className="af-class-desktop-newsletter-2"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                      <div className="af-class-text-block-61">Chance to Get Free Credit</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-mobile-newsletter">
                  <div className="af-class-div-block-48"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                    <div className="af-class-text-block-61">Join Newsletter</div>
                  </div>
                  <div className="af-class-div-block-48"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                    <div className="af-class-text-block-61">Stay Up To Date</div>
                  </div>
                </div>
                <div className="af-class-mobile-newsletter-2"><img src="images/check.png" loading="lazy" alt className="af-class-image-44" />
                  <div className="af-class-text-block-61">Chance to Get Free Credit</div>
                </div>
              </div>
            </div>
            <div data-w-id="ce50a293-efab-a9fb-a6e2-f67fb4591da2" style={{opacity: 0}} className="af-class-progress-container-moving-main">
              <div data-w-id="8abeb234-5d95-73cb-11e5-26c2cdf7e63d" className="af-class-progress-bar" />
            </div>
            <div data-w-id="080f47db-36af-f7f3-9484-8a4507aa735c" style={{opacity: 0}} className="af-class-progress-container-main">
              <div className="af-class-progress-bar-grey" />
            </div>
            <div data-w-id="e7535a09-c552-c880-0f66-1dbfd02c3e88" style={{opacity: 0}} className="af-class-to-top">
              <a href="#Top" className="w-inline-block">
                <div data-w-id="c9fca77f-4780-5f6f-228c-fb91799b5ca6" data-animation-type="lottie" data-src="documents/lf30_editor_jjqsxqjm.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration={2} data-duration={0} />
              </a>
            </div>
            <div className="af-class-first-splash af-class-_1" />
            <div className="af-class-splash-1-5 af-class-_1" />
            <div className="af-class-splash-2 af-class-_1" />
            <div className="af-class-splash-2-5 af-class-_1" />
            <div className="af-class-splash-3 af-class-_1" />
            <div className="af-class-homepage-footer">
              <div className="af-class-footer-items-container">
                <a href="index.html" aria-current="page" className="af-class-robinland-link w-inline-block w--current"><img src="images/Logo.png" loading="lazy" alt className="af-class-image-13" /></a>
                <div className="af-class-footer-item">
                  <a href="https://docs.robinland.io/" target="_blank" className="af-class-link-1">Docs</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-link-2">LinkedIn</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-link-2">Twitter</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-link-2">Medium</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.blog.robinland.io/" target="_blank" className="af-class-blog-link">Blog</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="mailto:team@robinland.io?subject=Reaching%20Out!" className="af-class-email-link">team@robinland.io</a>
                </div>
              </div>
              <div className="af-class-copyright">Copyright © Robinland Holdings LLC., 2022. All Rights Reserved.</div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */