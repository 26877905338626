/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  //{ loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61947f7302848e0ea77e8915").then(body => body.text()), isAsync: false },
  //{ loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class NotFoundView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/NotFoundController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = NotFoundView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '622b0695493fdc9a9ad86d6c'
    htmlEl.dataset['wfSite'] = '61947f7302848e0ea77e8915'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = NotFoundView.Controller !== NotFoundView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/robinland.webflow.css);

          ::-webkit-scrollbar{ width:0px; height:0px; }
        ` }} />
        <span className="af-view">
          <div className="af-class-body-4">
            <div className="af-class-div-block-45">
              <div data-w-id="3dac9d85-1aef-c99e-65bc-a46525e2a51f" data-animation-type="lottie" data-src="documents/lf30_editor_m1fnyj8z.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration={2} data-duration={0} className="af-class-lottie-animation-19" />
            </div>
            <a href="index.html" className="af-class-back-to-home w-inline-block"><img src="images/log.png" loading="lazy" alt /></a>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default NotFoundView

/* eslint-enable */