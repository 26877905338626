/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  //{ loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61947f7302848e0ea77e8915").then(body => body.text()), isAsync: false },
  //{ loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]
const partnerRegisterUrl = process.env.REACT_APP_ROBINLAND_API_URL + "/api/partner"

let Controller

class GetInvolvedView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/GetInvolvedController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = GetInvolvedView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '622b0695493fdc0784d86d71'
    htmlEl.dataset['wfSite'] = '61947f7302848e0ea77e8915'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = GetInvolvedView.Controller !== GetInvolvedView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/robinland.webflow.css);

          ::-webkit-scrollbar{ width:0px; height:0px; }
        ` }} />
        <span className="af-view">
          <div className="af-class-get-involved-page">
            <div data-animation="default" className="af-class-fullscreen-nav w-nav" data-easing2="ease-out" data-easing="ease-in" data-collapse="all" role="banner" data-no-scroll={1} data-duration={0} id="Top-Of-Page" data-doc-height={1}>
              <nav id="Navigation" data-w-id="aba33bfd-b834-53e1-af49-09db9b937330" className="af-class-nav-items">
                <a href="index.html" className="af-class-link-block-56 w-inline-block"><img src="images/Logo.png" width={200} alt className="af-class-robinland-icon" /></a>
                <div data-w-id="aba33bfd-b834-53e1-af49-09db9b937334" className="af-class-hamburger-menu-2 w-nav-button">
                  <div className="af-class-menu">
                    <div className="af-class-line" />
                    <div className="af-class-line-2" />
                  </div>
                </div>
                <div className="af-class-buttons-container">
                  <a href="projects.html" className="af-class-button-projects w-inline-block">
                    <div>View Projects</div>
                  </a>
                  <a href="/#claim-early-access" className="af-class-button-claim-early-access w-inline-block">
                    <div className="af-class-text-block-65">Claim Early Access</div>
                  </a>
                </div>
                <nav role="navigation" className="af-class-drop-down-menu-2 w-nav-menu">
                  <div className="af-class-columns-23 w-row">
                    <div className="w-col w-col-6 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">DISCOVER</div>
                      <a href="get-involved.html" aria-current="page" className="af-class-involve-link w-inline-block w--current">
                        <div className="af-class-text-block-43">Involve.</div>
                      </a>
                      <a href="projects.html" className="af-class-projects-link w-inline-block">
                        <div className="af-class-text-block-43">Projects.</div>
                      </a>
                    </div>
                    <div className="af-class-column-20 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">CONTACT</div>
                      <a href="mailto:team@robinland.io?subject=Reaching%20out" className="af-class-link-block-29 w-inline-block">
                        <div className="af-class-text-block-41">team@robinland.io</div>
                      </a>
                    </div>
                    <div className="af-class-column-19 w-col w-col-3 w-col-tiny-tiny-stack">
                      <div className="af-class-text-block-42">SOCIALS</div>
                      <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-link-block-26 w-inline-block">
                        <div className="af-class-text-block-40">LinkedIn</div>
                      </a>
                      <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Twitter</div>
                      </a>
                      <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-link-block-28 w-inline-block">
                        <div className="af-class-text-block-40">Medium</div>
                      </a>
                    </div>
                  </div>
                </nav>
              </nav>
            </div>
            <div className="af-class-the-form w-row">
              <div className="af-class-column-61 w-col w-col-6">
                <div data-w-id="2fa84a94-edfb-ff12-3281-9a35a8557869" style={{opacity: 0}} className="af-class-get-involved">Hey there,<br />Get In Touch.</div>
                <div data-w-id="2dd765c9-baab-5cba-b9f3-f4ce980c0bd2" style={{opacity: 0}} className="af-class-form-block w-form">
                  <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form" method="post" action={partnerRegisterUrl}>
                    <div className="af-class-contact-form-grid">
                      <div id="w-node-_2dd765c9-baab-5cba-b9f3-f4ce980c0bd5-84d86d71" className="af-class-div-block-13"><input type="text" className="af-class-your-name w-input" maxLength={256} name="Company-Name" data-name="Company Name" placeholder="Company Name" id="Company-Name" required /></div>
                      <div className="af-class-div-block-13"><input type="text" className="af-class-business-type w-input" maxLength={256} name="Business-Type" data-name="Business Type" placeholder="Business Type" id="Business-Type" required /></div>
                      <div id="w-node-_2dd765c9-baab-5cba-b9f3-f4ce980c0bd7-84d86d71" className="af-class-div-block-14"><input type="text" className="af-class-company w-input" maxLength={256} name="Email" data-name="Email" placeholder="Email" id="Email" required /></div>
                      <div className="af-class-div-block-15"><textarea id="Message" name="Message" maxLength={5000} data-name="Message" placeholder="Message" required className="af-class-message w-input" defaultValue={""} /></div>
                    </div><input type="submit" defaultValue="Submit" data-wait="Please wait..." className="af-class-submit-button w-button" />
                  </form>
                  <div className="af-class-success-message w-form-done">
                    <div className="af-class-div-block-29">Thank you! We will reach out shortly.</div>
                  </div>
                  <div className="af-class-error-message w-form-fail">
                    <div className="af-class-div-block-30">Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
              <div className="af-class-column-23 w-col w-col-6">
                <div data-w-id="2c7c2cbb-8d46-b16a-7320-624e32b66674" className="af-class-card-block"><img src="images/Glassy-card.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 39vw, (max-width: 1279px) 33vw, (max-width: 1439px) 35vw, (max-width: 1919px) 28vw, 29vw" srcSet="images/Glassy-card-p-500.png 500w, images/Glassy-card.png 662w" alt className="af-class-card" /></div>
              </div>
            </div>
            <div className="af-class-get-involved-footer">
              <div className="af-class-footer-items-container">
                <a href="index.html" className="af-class-robinland-link w-inline-block"><img src="images/Logo.png" loading="lazy" alt className="af-class-image-13" /></a>
                <div className="af-class-footer-item">
                  <a href="https://app.gitbook.com/s/YRBh3yDNriL3L9850qvK/" target="_blank" className="af-class-link-1">Docs</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.linkedin.com/company/robinland/" target="_blank" className="af-class-link-2">LinkedIn</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://twitter.com/Robinlandio" target="_blank" className="af-class-link-2">Twitter</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://robinlandio.medium.com/" target="_blank" className="af-class-link-2">Medium</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="https://www.blog.robinland.io/" target="_blank" className="af-class-blog-link">Blog</a>
                </div>
                <div className="af-class-footer-item">
                  <a href="mailto:team@robinland.io?subject=Reaching%20Out!" className="af-class-email-link">team@robinland.io</a>
                </div>
              </div>
              <div className="af-class-copyright">Copyright © Robinland Holdings LLC., 2022. All Rights Reserved.</div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default GetInvolvedView

/* eslint-enable */