import React from "react";
import { GetInvolvedView } from "../views";

class GetInvolvedController extends React.Component {

    componentDidMount() {
        // Load jquery library first for ajax script
        // https://stackoverflow.com/questions/26273833/console-error-with-ajax-referenceerror-is-not-defined/26273902
        const jqueryScript = document.createElement("script");
        jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js";
        jqueryScript.async = false;
        document.body.appendChild(jqueryScript);
        
        const script = document.createElement("script");
        script.src = "js/partnerRegister.js";
        script.async = false;
        document.body.appendChild(script);
    }

    render() {
        return (
            <GetInvolvedView>
            </GetInvolvedView>
        )
    }
}

export default GetInvolvedController